import {graphql, Link} from 'gatsby';
import React from 'react';

import {Button} from '../../../components/button';
import {Cta} from '../../../components/cta';
import {Layout} from '../../../components/layout';

const locale = 'en';

export default ({data}: any) => (
    <Layout
        title="Continuously improve and develop the software product – Step 4B of our approach"
        description="A software product is never finished. We must maintain momentum and involvement, and avoid stagnation. That is why we make agreements about periodic development."
        ogImage="continuous-development-en.png"
        translatedPagePath="/nl/werkwijze/continue-doorontwikkeling/"
        locale={locale}>
        <Link
            to="/en/approach/service/"
            className="body__nav body__nav--left"
            title="Approach step 4A. Serving software">
            Previous step
        </Link>

        <Link
            to="/en/approach/capability-transfer/"
            className="body__nav body__nav--right"
            title="Approach step 5. Capability transfer ">
            Next step
        </Link>

        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Continuous <br /> improve&shy;ment and <br /> development
                        </h2>
                        <div className="display__number">4b</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            A software product is never finished. We need to keep up the momentum and involvement and
                            prevent stagnation. That’s why we agree arrange&shy;ments for periodic develop&shy;ment.
                        </p>
                        <p>
                            After the launch we keep working in the same iterative process used during product
                            realisation. Now full-scale <span className="highlight">user feedback</span> can be
                            collected constantly. This enables us to respond more directly to end users’ wishes.
                        </p>
                        <p>
                            We <span className="highlight">optimise</span> and{' '}
                            <span className="highlight">improve</span> existing features and user experience and ensure
                            that the codebase stays up to date with changes to fundamental technologies used to develop
                            the software. Even more importantly, <span className="highlight">new features</span> that
                            further enrich the product are developed and realised.
                        </p>
                        <p>
                            After concluding an iteration and processing feedback, we launch directly to the production
                            environment. Which means end users benefit continually and almost immediately from the
                            development.
                        </p>
                        <ul className="list">
                            <li className="ion-md-arrow-dropright">Iterations of 1 &ndash; 2 weeks</li>
                            <li className="ion-md-arrow-dropright">At least 1 iteration per month</li>
                            <li className="ion-md-arrow-dropright">1 &ndash; 4 specialists</li>
                        </ul>
                    </div>
                    <div className="content__buttons content__buttons--50">
                        <Button
                            url="/en/approach/capability-transfer"
                            name="Next step: development transition"
                            title="Approach step 5. Development transition"
                        />
                    </div>
                </div>
            </article>

            <Cta image={data.olav.childImageSharp.fixed} locale={locale} />
        </section>
    </Layout>
);

export const query = graphql`
    query {
        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }
    }
`;
